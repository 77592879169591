import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';

Rails.start();
ActiveStorage.start();



  import '/tmp/build_388f7655/app/javascript/application/javascripts/application.js';

  import '/tmp/build_388f7655/app/javascript/application/javascripts/backend/index.js';

  import '/tmp/build_388f7655/app/javascript/application/javascripts/mixins/formFieldsMixin.js';

  import '/tmp/build_388f7655/app/javascript/application/javascripts/router/index.js';


