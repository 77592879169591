<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <div class="columns">
      <div class="column is-3">
        <label class='label'>Label</label>
        <p>{{review.label}}</p>
      </div>

      <div class="column">
        <label class='label'>Review Type</label>
        <p>{{review.friendly_type}}</p>
      </div>

      <div class="column is-2">
        <label class='label'>Group(s)</label>
        <div class="content">
          <ul>
            <li v-for="group in review.groups">{{group.name}}</li>
          </ul><br/>
        </div>
      </div>

      <div class="column">
        <label class='label'>Status</label>
        <p><span :class="'tag ' + review.status_class">{{review.friendly_state}}</span></p>
      </div>

      <div class="column">
        <label class='label'>Review Status</label>
        <p>{{Math.round(review.pct_complete * 100)}}% Complete</p>
      </div>

      <div class="column">
        <label class='label'>Deadline</label>
        <p>{{review.friendly_deadline_at}}</p>
      </div>  
    </div>

    <div class="content" v-if="review.current_state === 'not_started'">
      <p>Review is not yet started. The following documents are queued to be included in the review: </p>
      <ul>
        <li v-for="reviewable in review.reviewables"><a :href="reviewable.url">{{reviewable.title}}</a></li>
      </ul>
    </div>
    <div v-else>
      <div id="overview-table">
        <template>
          <section>
            <b-table :data="review.reviewables" detailed detail-key="title">
              <template slot-scope="props">
                <b-table-column field="title" label="Document" width="500">
                  <a v-bind:href="props.row.url" target="_blank">{{ props.row.title }}</a>
                </b-table-column>   

                <b-table-column field="document_type" label="Document Type" centered>
                  <span class='tag' v-bind:class=props.row.document_type_class>
                    {{ props.row.document_type }}
                  </span>
                </b-table-column>   

                <b-table-column v-if="review.display_flags.show_is_revision" field="revision" label="Revision" centered>
                  <span class='tag' v-bind:class=props.row.document_subtype_class>
                    {{ props.row.is_revision }}
                  </span>
                </b-table-column>

                <b-table-column v-if="review.display_flags.show_published_at" field="published_at" label="Published At" centered>
                  {{ props.row.display_published_at }}
                </b-table-column>

                <b-table-column v-if="review.display_flags.show_published_at" field="review_interval" label="Review Interval" centered>
                  {{ props.row.review_interval }}
                </b-table-column>                

                <b-table-column v-if="review.display_flags.show_scores_and_rank" field="review_status" label="Review Status" numeric>
                  {{ props.row.review_status }}
                </b-table-column>                

                <b-table-column v-if="review.display_flags.show_scores_and_rank" field="total_score" label="Score" numeric>
                  {{ props.row.total_score }}
                </b-table-column>         

                <b-table-column v-if="review.display_flags.how_scores_and_rank" field="rank" label="Rank" numeric>
                  {{ props.row.rank }}
                </b-table-column>     

                <b-table-column v-if="review.display_flags.show_mcq" field="friendly_multiple_choice" label="MCQ" centered>
                  {{ props.row.friendly_multiple_choice }}
                </b-table-column>         

                <b-table-column v-if="review.display_flags.show_reviewer_support" field="reviewer_support" label="Reviewer Support">
                  <span class="icon has-text-success"><i class="fas fa-check-circle"></i></span>{{ props.row.reviewer_support['yes'] }}%<br/>
                  <span class="icon has-text-danger"><i class="fas fa-times-circle"></i></span>{{ props.row.reviewer_support['no'] }}%<br/>
                  <span class="icon has-text-grey-light"><i class="fas fa-question-circle"></i></span>{{ props.row.reviewer_support['n/a'] }}%
                </b-table-column>       

                <b-table-column v-if="review.display_flags.show_decision" field="review_target_state" label="Decision" centered>
                  <span class='tag' v-bind:class=props.row.review_target_state_class v-if='props.row.review_target_set'>
                    {{ props.row.review_target_state_display }}
                  </span>
                  <b-icon type="is-warning" icon="exclamation-triangle" class="is-small" v-else='props.row.review_target_set'></b-icon>
                </b-table-column>       
              </template>

              <template slot="detail" slot-scope="props">
                <div class="content">
                  <label class='label' v-if="props.row.staff_comment && props.row.staff_comment.length">Staff Comment</label>
                  <ul>
                    <li v-if="props.row.staff_comment && props.row.staff_comment.length">{{ props.row.staff_comment }}</li>
                  </ul>

                  <div v-if="review.display_flags.show_reviewer_comments">
                    <label class='label'>Reviewer Comments</label>
                    <ul>
                      <li v-if="!props.row.review_comments.length">[no comments]</li>
                      <li v-for="review_comment in props.row.review_comments" v-if="review_comment.comment && review_comment.comment.length">
                        {{ review_comment.comment }} <small>[{{ review_comment.user }} / {{ review_comment.group }}]</small>
                      </li>
                    </ul>
                  </div>
                  
                  <label class='label' v-if="props.row.recommendation">Recommendation</label>
                  <ul>
                    <li v-if="props.row.recommendation">{{ props.row.recommendation }}</li>
                  </ul>
                </div>
              </template>          
            </b-table>
          </section>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "OverviewTab",
    props: {
      id: String
    },
    mounted () {
      this.getReview()
    },
    data: function() {
      return {
        review: {},
        isLoading: true
      };
    },
    methods: {
      getReview() {
        var url = `/reviews/${this.id}`

        this.$secured.get(url)
          .then(response => {
            this.review = response.data

            // sort the reviewables
            this.review.reviewables = this.review.reviewables.sort((a, b) => parseFloat(b.total_score) - parseFloat(a.total_score))
            this.isLoading = false
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Failed to get review: ${error}`,
              position: 'is-bottom',
              type: 'is-danger'
            })    
          }) 
      }
    }
  }
</script>