import { render, staticRenderFns } from "./RequestChangesModal.vue?vue&type=template&id=1d4ca109&scoped=true&"
import script from "./RequestChangesModal.vue?vue&type=script&lang=js&"
export * from "./RequestChangesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4ca109",
  null
  
)

export default component.exports