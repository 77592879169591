<template>
  <div>
    <form id="groupForm" @submit.prevent="processForm()">
      <div class="columns">
        <div class="column">
          <b-field label="Name" :type="fieldType('name')" :message="fieldErrors('name')">
            <b-input v-model="group.name" :autofocus="true"></b-input>
          </b-field>          
        </div>

        <div class="column">
          <b-field label="Group Type" :type="fieldType('group_type')" :message="fieldErrors('group_type')">
            <b-select v-model="group.group_type" is-fullwidth>
              <option v-for="groupType in groupTypes">{{groupType}}</option>
            </b-select>
          </b-field>      
        </div>

        <div class="column">
          <b-field label="Status" :type="fieldType('active')" :message="fieldErrors('active')">
            <b-checkbox v-model="group.active">
              Active
            </b-checkbox>
          </b-field>

          <b-field label="Attributes" :type="fieldType('can_review')" :message="fieldErrors('can_review')">
            <b-checkbox v-model="group.can_review">
              Can Review?
            </b-checkbox>
          </b-field>
        </div>        
      </div>
      
      <hr>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Member</th>
            <th>Organization</th>
            <th>Role</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in group.group_members" :key="member.id">
            <td>
              <b-field label="" :type="fieldType('user_id')" :message="fieldErrors('user_id')">
                <b-select v-model="member.user_id" is-fullwidth>
                  <option v-for="user in users" :key="user.id" :value="user.id">{{user.first_name}} {{user.last_name}}</option>
                  }
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field label="" :type="fieldType('name')" :message="fieldErrors('name')">
                <b-input v-model="member.organization"></b-input>
              </b-field>                  
            </td>
            <td>
              <b-field label="" :type="fieldType('role_type_id')" :message="fieldErrors('role_type_id')">
                <b-select v-model="member.role_type_id" is-fullwidth>
                  <option v-for="roleType in filteredRoleTypes" :key="roleType.id" :value="roleType.id">{{roleType.position}}</option>
                </b-select>
              </b-field>
            </td>      
            <td>
              <b-field label="" :type="fieldType('active')" :message="fieldErrors('active')">
                <b-checkbox v-model="member.active">
                  Active
                </b-checkbox>
              </b-field>
            </td>
            <td>
              <b-field label="" :type="fieldType('_destroy')" :message="fieldErrors('_destroy')">
                <b-checkbox v-model="member._destroy">
                  Delete
                </b-checkbox>
              </b-field>
            </td>        
          </tr>
        </tbody>
      </table>

      <hr>
      <b-button class='is-success' native-type="submit">Save Changes</b-button>
      <b-button class='is-default' @click="addMember">Add Member</b-button>
    </form>
  </div>
</template>

<script>
import formFieldsMixin from '@/mixins/formFieldsMixin.js'

export default {
  name: "FormGroup",
  props: {
    id: String
  },
  mixins: [formFieldsMixin],  
  mounted () {
    this.getUsers()
    this.getRoleTypes()

    if(this.isEdit()) {
      this.getGroup()
    }    
  },
  data: function() {
    return {
      group: {
        active: true,
        group_type: 'committee',
        group_members: []
      },
      groupTypes: [
        'committee',
        'section',
        'board',
        'task_force',
        'staff'
      ],
      roleTypes: [],
      users: [],
      errors: {}
    }
  },
  computed: {
    filteredRoleTypes () {
      var roleTypes = this.roleTypes

      roleTypes = roleTypes.filter(element => element.group_type === this.group.group_type)
      return roleTypes      
    }
  },
  methods: {
    getGroup () {
      var url = `/admin/groups/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.group = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get group: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },
    getUsers () {
      var url = `/admin/users`

      this.$secured.get(url)
        .then(response => {
          this.users = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get users: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },    
    getRoleTypes () {
      var url = `/admin/role_types`

      this.$secured.get(url)
        .then(response => {
          this.roleTypes = response.data
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to get role types: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        }) 
    },        
    addMember () {
      this.group.group_members.push({ id: null, role_type_id: this.roleTypes[0].id, user_id: this.users[0].id, organization: null, active: true, _destroy: 0 })
    },
    processForm () {
      var groupMembers = this.group.group_members.map(member => { 
        return { 
          id: member.id, 
          user_id: member.user_id, 
          organization: member.organization, 
          role_type_id: member.role_type_id,
          active: member.active,
          _destroy: member._destroy
        }
      })
      var params = {
        group: {
          name: this.group.name,
          group_type: this.group.group_type,
          active: this.group.active,
          can_review: this.group.can_review,
          group_members_attributes: groupMembers
        }
      }

      if(this.isEdit()) {
        this.updateGroup(params)
      } else {
        this.addGroup(params)
      }
    },
    addGroup (params) {
      this.$secured.post('/admin/groups', params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully added group`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = '/admin/groups'
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to add group: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    },
    updateGroup (params) {
      this.$secured.put(`/admin/groups/${this.id}`, params)
      .then((response) => {
        response

        this.$buefy.toast.open({
          duration: 2000,
          message: `Successfully updated group`,
          position: 'is-bottom',
          type: 'is-success'
        })    

        window.location.href = '/admin/groups'
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: `Failed to update group: ${error}`,
          position: 'is-bottom',
          type: 'is-danger'
        })    
      })   
    }
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
